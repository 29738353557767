<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-4 pt-4">
      <h6 class="mb-0 text-h6 font-weight-bold text-typo">Calendar</h6>
      <div class="d-flex">
        <div
          class="
            p
            text-sm text-capitalize
            font-weight-bold
            mb-0
            widget-calendar-day
            text-body
          "
        >
          {{ new Date().toLocaleString("default", { weekday: "long" }) }}
        </div>
        <span class="text-body">,</span>
        <div
          class="
            p
            text-sm
            font-weight-bold
            mb-1
            widget-calendar-year
            text-body
            ms-1
          "
        >
          {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
    <div class="px-4 py-4">
      <full-calendar
        :options="calendarOptions"
        ref="fullCalendar"
      ></full-calendar>
    </div>
  </v-card>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
 
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        headerToolbar: {
          center: "dayGridMonth,timeGridFourDay", // buttons for switching between views
        },
        initialView:'timeGridFourDay',
        views: {
          timeGridFourDay: {
            type: "timeGrid",
            duration: { days: 4 },
            buttonText: "4 day",
          },
        },
      },
    };
  },
};
</script>
